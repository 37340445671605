import { ServiceArea } from '../returns/service-area/service-area';
import { AccountDetails } from '../account-details/account-details';
import { ContactTime } from '../contact-time/contact-time';
import { Note } from '../note/note';
import { Validator } from '../validator/validator';
import { Evidence } from '../evidence/evidence';
import { Search } from '../search/search';
import { Offense } from '../offense/offense';

export class Warrant {
  //*-----------Warrant-----------*//
  //-----Info-----//
  /** AWS ID of the search warrant */
  id?: string;
  /** What kind of warrant it is */
  type?: 'warrant' | 'gps' | 'order' | 'specialized';
  /** Search warrant report number */
  reportNumber?: string;
  /** Description of the warrant */
  description?: string;
  /** City in which the warrant takes place */
  city?: string;
  /** County in which the warrant takes place */
  county?: string;
  /** State in which the warrant takes place */
  state?: string;
  /** Narrative of the warrant */
  narrative?: string;
  /** If the warrant was replicated from another warrant */
  isReplicated?: boolean;

  //-----Nested Objects-----//
  /** Evidences to be collected or siezed */
  evidences?: Evidence[];
  /** Persons / Premises / Vehicles / Other objects to be searched */
  searches?: Search[];
  /** Offenses Commited */
  offenses?: Offense[];

  //-----Court Order Characteristics-----//
  /** The court is requesting photos of a subject */
  isPhoto?: boolean;
  /** If the photo request struck by the court */
  isPhotoIsStruck?: boolean;
  /** The court is requesting prints of a subject */
  isPrints?: boolean;
  /** If the print request struck by the court */
  isPrintsIsStruck?: boolean;
  /** The court is requesting saliva of a subject */
  isSaliva?: boolean;
  /** If the saliva request struck by the court */
  isSalivaIsStruck?: boolean;
  /** The court is requesting blood of a subject */
  isBlood?: boolean;
  /** If the blood request struck by the court */
  isBloodIsStruck?: boolean;
  /** The court is requesting urine of a subject */
  isUrine?: boolean;
  /** If the urine request struck by the court */
  isUrineIsStruck?: boolean;
  /** The court is requesting hair of a subject */
  isHair?: boolean;
  /** If the hair request struck by the court */
  isHairIsStruck?: boolean;
  /** The court is requesting handwriting of a subject */
  isHandwriting?: boolean;
  /** If the Handwriting request struck by the court */
  isHandwritingIsStruck?: boolean;
  /** The court is requesting measurements of a subject */
  isMeasurements?: boolean;
  /** If the measurements request struck by the court */
  isMeasurementsIsStruck?: boolean;
  /** The court is requesting the voice of a subject */
  isVoice?: boolean;
  /** If the voice request struck by the court */
  isVoiceIsStruck?: boolean;
  /** The court is requesting the footprints of a subject */
  isFootprints?: boolean;
  /** If the foorprints request struck by the court */
  isFootprintsIsStruck?: boolean;

  //-----Grounds for Issuance-----//
  /** Property was stolen or embezzled */
  isStolen?: boolean;
  /** Property was used as a means to commit an offense */
  isMeans?: boolean;
  /** Property possessed may be used as a means to commit an offense */
  isPossessed?: boolean;
  /** Property may be in possession of someone attempting to conceal it or prevent it from being discovered */
  isConcealed?: boolean;
  /** Property may constitute evidence that an offense occured */
  isEvidence?: boolean;
  /** Person sought has an outstanding warrant */
  isPerson?: boolean;
  /** If the record was a means to commit a crime */
  recordsIsMeans?: boolean;
  /** If the records was evidence that a crim was commited */
  recordsIsEvidence?: boolean;
  /** If the record contained communication */
  recordsIsCommunication?: boolean;

  //-----Service Time-----//
  /** When the warrant is to be served: day, night, anytime */
  serviceTime?: 'day' | 'night' | 'anytime';
  /** If the service time is not day, then why | Why the GPS warrant requires delayed notice */
  serviceTimeExplanation?: string;
  /** If the service time is struck by the court */
  serviceTimeIsStruck?: boolean;

  //-----Affiant Info-----//
  /** Badge of the affiant */
  affiantBadge?: string;
  /** Cell number of the affiant */
  affiantCell?: string;
  /** Email of the affiant */
  affiantEmail?: string;
  /** Fax of the affiant */
  affiantFax?: string;
  /** First name of the affiant */
  affiantFirstName?: string;
  /** Last name of the affiant */
  affiantLastName?: string;
  /** Title of the affiant */
  affiantTitle?: string;
  /** URL of the signature signed on the warrant */
  signatureImg?: string;
  /** Affiant Fax */
  myFax?: string;
  /** Affiant Email */
  myEmail?: string;
  /** Affiant Phone */
  myPhone?: string;
  /** Do not show the affiant signature (to be done in person) */
  showNoSig?: boolean;

  //-----Agency Info-----//
  /** AWS ID of the Agency that created the warrant */
  agencyId?: string;
  /** The account that created the warrant */
  creatorId?: string;
  /** Status of the warrant (deptApproved is deprecated and replaced by the 'court' statuses, manualapproval is deprecated to courtManualPending) */
  /**
   * Current statuses
   * 'draft' | 'pending'  | 'courtManualPending' | 'courtSubmitted' | 'courtApproved' | 'courtDenied' | 'served' | 'notServed'
   * Deprecated statuses
   * 'deptapproved' | 'manualapproval';
   **/
  status?:
    | 'draft'
    | 'pending'
    | 'courtManualPending'
    | 'courtSubmitted'
    | 'courtApproved'
    | 'courtDenied'
    | 'served'
    | 'notServed'
    | 'deptapproved'
    | 'manualapproval'
    | 'archived'; // archived added for Achive function
  /** Name of the agency */
  agencyName?: string;

  //------Optional Items-----//
  // Warrant Options
  /** Delayed notice is requested */
  isDelayed?: boolean;
  /** Delayed notice explanation */
  delayedExplanation?: string;
  /** If the court strikes the delayed notice request */
  isDelayedIsStruck?: boolean;
  /** Expedited Review is requested */
  isExpedited?: boolean;
  /** Reason for expedited review is homicide */
  isExpeditedHomicide?: boolean;
  /** Reason for expedited review is an officer-involved shooting investigation */
  isExpeditedOfficer?: boolean;
  /** Reason for expedited review is law enforcement is standing by waiting for the warrant to be complete */
  isExpeditedStandy?: boolean;
  /** Reason for expedited review is public right of ways have been closed until the matter is resolved */
  isExpeditedPublic?: boolean;
  /** Reason for expedited review is people have been displaced until the matter is resolved */
  isExpeditedDisplaced?: boolean;
  /** If there is another reason for why the warrant must be expedited */
  isExpeditedOther?: boolean;
  /** Other explanation for why the warrant must be expedited */
  expeditedExplanation?: string;
  /** Requested supervisor's name for expedited review */
  expeditedSupervisorName?: string;
  /** Requested supervisor's phone number for expedited review */
  expeditedSupervisorPhone?: string;
  /** Training and experience */
  experience?: string;
  /** Informant narrative */
  informant?: string;
  /** If the warrant is sealed */
  isSealed?: boolean;
  /** Explanation as to why the warrant is sealed */
  sealedExplanation?: string;
  /** If the request to be sealed has been struck by the court */
  sealedIsStruck?: boolean;
  /** No knock is requested */
  isNoKnock?: boolean;
  /** Explanation of no knock requested */
  noKnockExplanation?: string;
  /** If the no knock is struck by the court */
  noKnockIsStruck?: boolean;
  /** Delay the cell phone download */
  isCellPhoneDownloadDelay?: boolean;
  /** Explanation for why the cell phone download should be delayed */
  cellPhoneDownloadDelayExplanation?: string;
  /** If the cell phone downlaod delay is struck */
  cellPhoneDownloadDelayIsStruck?: boolean;

  // Social Media Options
  /** If the records warrant should not be disclosed to the user */
  isNonDisclosure?: boolean;
  /** Non disclosure is to protect the physical safety of an individual */
  nonDisclosureIsEndangering?: boolean;
  /** Non disclosure is to prevent flight from prosecution */
  nonDisclosureIsFlight?: boolean;
  /** Non disclosure is to prevent destruction of evidence */
  nonDisclosureIsDestruction?: boolean;
  /** Non disclosure is to prevent intimidation of a witness */
  nonDisclosureIsIntimidation?: boolean;
  /** Non disclosure is to prevent the jeapordizing of an investigation */
  nonDisclosureIsJeapardizing?: boolean;
  /** If the records warrant non-disclosure order was struck */
  nonDisclosureIsStruck?: boolean;

  //-----Company Info-----//
  /** Name of the company holding records */
  companyName?: string;
  /** Address of the company holding records */
  companyAddress?: string;
  /** Address line 2 of the company holding records */
  companyAddress2?: string;
  /** City in which the company holding records is located */
  companyCity?: string;
  /** State in which the company holding records is located */
  companyState?: string;
  /** Zip code in which the company holding records is located */
  companyZip?: string;
  /** Email of the company holding records */
  companyEmail?: string;
  /** Phone number of the company holding records */
  companyPhone?: string;
  /** Description of the company holding records */
  companyExplanation?: string;
  /** Investigation background with the company holding records */
  companyInvestigativeBackground?: string;
  /** Date when the records begin */
  recordsStart?: string;
  /** Date when the records end */
  recordsEnd?: string;
  /** Date the preservation request was made */
  preservationRequestDate?: string;
  /** Number of the preservation request */
  preservationRequestNumber?: string;
  /** Record account details */
  accounts?: AccountDetails[];

  //-----Special Orders-----//
  // Special Orders
  /** Records warrant CA Penal Code special order */
  specialOrderIsCAPenalCode?: boolean;
  /** Records warrant Records Authenticity special order */
  specialOrderIsAuthenticityRecord?: boolean;
  /** If the Records warrant Records Authenticity special order was struck */
  specialOrderIsAuthenticityRecordIsStruck?: boolean;
  /** Records warrant Adverse Action special order */
  specialOrderIsAdverseAction?: boolean;
  /** If the Records warrant Adverse Action special order was struck */
  specialOrderIsAdverseActionIsStruck?: boolean;
  /** Records warrant Associated Numbers special order */
  specialOrderIsAssociatedNumbersOrder?: boolean;
  /** If the Records warrant Associated Numbers special order was struck */
  specialOrderIsAssociatedNumbersOrderIsStruck?: boolean;
  /** Records warrant Fund Seizure special order */
  specialOrderIsFundSiezureOrder?: boolean;
  /** If the Records warrant Fund Seizure special order was struck */
  specialOrderIsFundSiezureOrderIsStruck?: boolean;

  // Phone Companies
  /** Records warrant Carrier Key and Cell Site special order */
  specialOrderisCarrierKeyAndCellSiteList?: boolean;
  /** If the Records warrant Carrier Key and Cell Site special order was struck */
  specialOrderisCarrierKeyAndCellSiteListIsStruck?: boolean;
  /** Records warrant Cell Site Simulator special order */
  specialOrderIsCellSiteSimulator?: boolean;
  /** If the Records warrant Cell Site Simulator special order was struck */
  specialOrderIsCellSiteSimulatorIsStruck?: boolean;
  /** Records warrant Pen Register and Trap and Trace special order */
  specialOrderIsPhonePenRegisterTrace?: boolean;
  /** If the Records warrant Pen Register and Trap and Trace special order was struck */
  specialOrderIsPhonePenRegisterTraceIsStruck?: boolean;
  /** Records warrant Cell Ping special order */
  specialOrderIsPing?: boolean;
  /** If the Records warrant Cell Ping special order was struck */
  specialOrderIsPingIsStruck?: boolean;
  /** Records warrant Service Transer special order */
  specialOrderIsServiceTransferOrder?: boolean;
  /** If the Records warrant Service Transer special order was struck */
  specialOrderIsServiceTransferOrderIsStruck?: boolean;
  /** Records warrant Vehicle Ping special order */
  specialOrderIsVehiclePingOrder?: boolean;
  /** If the Records warrant Vehicle Ping special order was struck */
  specialOrderIsVehiclePingOrderIsStruck?: boolean;

  // Social Media
  /** Records warrant Ongoing Location Data special order */
  specialOrderIsOngoingLocationData?: boolean;
  /** If the Records warrant Ongoing Location Data special order was struck */
  specialOrderIsOngoingLocationDataIsStruck?: boolean;

  //----- Units -----//
  /** Units the warrant belongs to */
  units?: string[];

  //-----Approve Info-----//
  /** Number of officers on standby (non-overtime) */
  standbyOfficers?: number;
  /** Number of officers on standby on overtime */
  standbyOfficersOT?: number;
  /** Number of supervisor on standby (non-overtime) */
  standbySupervisors?: number;
  /** Number of supervisor on standby on overtime */
  standbySupervisorsOT?: number;
  /** Title of the supervisor approving the warrant */
  supervisorTitle?: string;
  /** First name of the supervisor approving the warrant */
  supervisorFirstName?: string;
  /** Last name of the supervisor approving the warrant */
  supervisorLastName?: string;
  /** Badge number of the supervisor approving the warrant */
  supervisorBadgeNumber?: string;
  /** Signature of the supervisor approving the warrant */
  supervisorSignature?: string;
  /** Do not show the supervisor signature (to be done in person) */
  showNoSupervisorSig?: boolean;
  /** If the warrant is being manually approved */
  isManualApproval?: boolean;

  //-----Service Information-----//
  /** Search areas during the service */
  serviceAreas?: ServiceArea[];
  /** Specialized Served Time */
  specializedServedTime?: string;
  /** Specialized Served Date */
  specializedServedDate?: string;
  /** Specialized Served method */
  specializedServedMethod?: 'inPerson' | 'mail' | 'fax' | 'email' | 'web';

  //------Validation-----//
  /** Validator ensuring all the pages are complete */
  validator?: Validator;

  //-----Time Stamps-----//
  /** Date of warrant setup */
  dateSetup?: string;
  /** Date of the warrant signature */
  dateSigned?: string;
  /** Date the warrant was submitted to a court */
  dateSubmitted?: string;
  /** Date the dept. approved the warrant */
  dateDeptApproved?: string;
  /** Date the court approved the warrant */
  dateCourtApproved?: string;
  /** Date the warrant was executed */
  dateExecuted?: string;

  //-----Analytics-----//
  /** Amount of money saved by using Centurion over conventional warrant creation from standby officer wages */
  standbySavings?: number;

  //*----------Court----------*//
  //-----Info-----//
  /** Id of the reviewing court */
  courtId?: string;
  /** Name of the destination court */
  courtName?: string;
  /** Fax number of the destination court */
  courtFax?: string;
  /** Email of the destination court */
  courtEmail?: string;
  /** Report number issued by the court */
  courtReportNumber?: string;

  //-----Notes-----//
  /** Notes seen in the public notes page */
  publicNotes?: Note[];
  /** Notes seen in the internal notes page */
  internalNotes?: Note[];

  //-----Review Info-----//
  /** Court's id of the reviewer(judge) who approved/denied the warrant */
  reviewerId?: string;
  /** Title of the reviewer(judge) who approved/denied the warrant */
  reviewerTitle?: string;
  /** First name of the reviewer(judge) who approved/denied the warrant */
  reviewerFirstName?: string;
  /** Name of the reviewer(judge) who approved/denied the warrant */
  reviewerLastName?: string;
  /** If the warrant was approved or not */
  courtApproved?: boolean;
  /** Information on why the warrant was denied */
  courtExplanation?: string;
  /** Status of the warrant within the court: submitted, approved, denied THIS IS LEGACY AND SHOULD BE REPLACED BY STATUS */
  courtStatus?: 'submitted' | 'approved' | 'denied' | 'archived';
  /** Times the officer was contacted about the warrant */
  contactedTimes?: ContactTime[];
  /** Times the oath was given to the judge */
  oathTimes?: ContactTime[];
  /** Times the judge attempted to contact the officer and was unsuccessful */
  noAnswerTimes?: ContactTime[];
  /** Reviewer signature as base64 string */
  reviewerSignature?: string;

  //-----Return Info-----//
  /** Person to whom a receipt of objects found was given */
  receiptPersons?: string[];
  /** Location at which a receipt of objects found was given */
  receiptLocations?: string[];
  /** Address to which a receipt of objects found was sent */
  receiptAddresses?: string[];
  /** If the warrant has been served */
  isServed?: boolean;
  /** Title of the affiant who marked the warrant as served */
  returnAffiantTitle?: string;
  /** First name of the affiant who marked the warrant as served */
  returnAffiantFirstName?: string;
  /** Last name of the affiant who marked the warrant as served */
  returnAffiantLastName?: string;
  /** Badge number of the affiant who marked the warrant as served */
  returnAffiantBadge?: string;
  /** Signature of the affiant who marked the warrant as served */
  returnAffiantSignature?: string;
  /** Show no return affiant signature */
  showNoReturnAffiantSig?: boolean;
  /** Title of the reviewer(judge) who confirmed the warrant as served */
  returnReviewerTitle?: string;
  /** First name of the reviewer(judge) who confirmed the warrant as served */
  returnReviewerFirstName?: string;
  /** Last name of the affreviewer(judge) who confirmed the warrant as served */
  returnReviewerLastName?: string;
  /** Badge number of the reviewer(judge) who confirmed the warrant as served */
  returnReviewerBadge?: string;
  /** Signature of the reviewer(judge) who confirmed the warrant as served */
  returnReviewerSignature?: string;
  /** When the return was created */
  tsReturnCreated?: string;
  /** When the return was reviewed */
  tsReturnReviewed?: string;
  /** If the return will be done manually in front of a judge */
  returnManualReturn?: boolean;
  /** If no knock was done on the warrant */
  isNoKnockCommitted?: boolean;
  /** If warrant was completed at night */
  isNightServiceCommitted?: boolean;
  /** If an officer entered a strcutre to execute the warrant */
  isOfficerEnteredStructure?: boolean;
  /** If the warrant was obtained for a DUI */
  isDui?: boolean;

  //-----Time Stamps-----//
  /** Timestamp of when the court requested warrant to review */
  tsCourtRequested?: string;
  /** Timestamp of when the court review was complete */
  tsCourtReviewed?: string;
  /** Date/Time the officer was called */
  tsOfficerCalled?: string;
  /** Time the warrant was submitted to the court */
  tsSubmittedToCourt?: string;
}
